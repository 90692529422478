import React, {useState, Fragment} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Card, FlexColumnCenterCenter, FlexColumnStartStart} from '../../widget';
import {BlockSelector} from '../../Components/BlockSelector';
import {Color} from '../../constants';

const COMMON_QUESTION_TYPE = {
  userQuestion: 'userQuestion',
  ticketQuestion: 'ticketQuestion',
  elseQuestion: 'elseQuestion',
};

const configs = [
  {name: '會員問題', value: 'userQuestion'},
  {name: '購票問題', value: 'ticketQuestion'},
  {name: '其他問題', value: 'elesQuestion'},
];

const questions = [
  {
    title: '如何加入網路會員?',
    context:
      '一般會員申請：請至ATT影城官網(or APP)進入「個人專區」進行申請，填寫完基本資料後，請至e-mail收取確認信，啟用帳號成功後，即可使用網路訂票功能。',
    type: COMMON_QUESTION_TYPE.userQuestion,
  },
  {
    title: '加入會員後一直收不到會員確認信?',
    context:
      '若是一直收不到確認信的原因及處理方法如下：\n' +
      '1. e-mail 帳號填錯或無效\n' +
      '處理：請洽ATT影城服務專線協助查詢修改。\n' +
      '2. 被e-mail 帳號的郵件主機阻擋或是當成垃圾信件\n' +
      '處理：若信箱是使用奇摩、Pchome等免費信箱，請由網路登入信箱並至垃圾信郵件區裡面檢視是否有會員確認信或申請更換帳號。\n' +
      '※若使用公司信箱註冊，請先確認 貴公司之資訊安全政策不會被阻擋。',
    type: COMMON_QUESTION_TYPE.userQuestion,
  },
  {
    title: '如何修改會員資料?',
    context:
      '點選「個人專區」 → 選擇「會員登入」→ 選擇「修改會員資料」，即可確認或修改個人相關資訊，包含但不限於姓名、性別、居住地區…等。\n' +
      '信箱及聯絡電話請洽ATT影城服務專線 (02)7700-7040 #****協助查詢修改。',
    type: COMMON_QUESTION_TYPE.userQuestion,
  },
  {
    title: '密碼忘記了怎麼辦?',
    context:
      '◎網站登入密碼忘記：\n' +
      '官網首頁點選會員服務 → 選擇「重置網路會員密碼」，請您輸入相關註冊資料，系統驗證成功後，將直接寄出新密碼至會員信箱。',
    type: COMMON_QUESTION_TYPE.userQuestion,
  },
  {
    title: '電影場次何時公布?',
    context:
      '◎週五上映之電影，新場次公布於每週三晚上18:00~20:00，開放未來一週(即當週的星期五至下週星期四為止)之場次。\n' +
      '◎週三上映之電影，新場次公布於每週一晚上20:00~22:00，開放週三、週四之場次。\n' +
      '◎週四上映之電影，新場次公布於每週二晚上20:00~22:00，開放週四之場次。\n' +
      '◎其他因預售活動或特殊假期、特殊開片日之電影，將另行公告場次開放時間。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: 'APP訂票後，需要到櫃台取票嗎?',
    context:
      '完成訂票後可用手機畫面驗證入場，免排隊取票。倘如需實體票券請至現場售票櫃檯取票。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '我想要在APP上面訂愛心票，該如何訂購呢?',
    context:
      '◎持身心障礙證明即可購買『愛心票』，票價為全票價之半價。持身心障礙證明，可以同價位加購同一場次陪同票一張。\n' +
      '◎持身心障礙證明，且必要陪伴者優惠措施欄位備註有『康樂場所』字樣者，可以同價位加購同一場次陪同票一張。若欄位無備註『康樂場所』字樣者，其購票優惠僅限本人使用。\n' +
      '◎線上訂購『愛心票』者請於取票時，出示有效之身心障礙證明，無證件者須以全票收費。\n' +
      '◎持『愛心票』入場觀影者，請於入場時出示本人有效之身心障礙證明，無證件者須補費至全票金額。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '我想要在APP上面訂愛心票，該如何訂購呢?',
    context:
      '◎凡滿65歲以上年長者即可購票，於購票時出示有效之身分證件，即可購票。\n' +
      '◎一張證件可購買『敬老票』一張，票價為全票價之半價。\n' +
      '◎線上訂購『敬老票』者，請於取票時，出示有效之身分證件，無證件者須以全票收費。\n' +
      '◎持『敬老票』入場觀影者，請於入場時出示本人之有效身分證件，無證件者須補費至全票金額。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '兒童優待票的購票限制?',
    context:
      '◎未滿2歲且不佔位之兒童無需購票可免費入場觀賞【普遍級】影片，每位兒童需由至少一位已購票之成人陪伴。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '電影片長150分鐘(含150分鐘)加價說明',
    context:
      '◎片長 150分鐘以上(150分鐘~179分鐘)之電影需加價 NT$10，片長 180分鐘以上(180分鐘~209分鐘)之電影需另再加價 NT$20，以此類推。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '銀行信用卡購票優惠可以網路預訂嗎？',
    context:
      '各銀行票種訂購所享之優惠與限制；皆以ATT影城營業日為準，而非以刷卡交易日認定，各卡別每日購票張數與適用優惠，依平假日與影片版本相關規定。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '如何取消網路訂票?',
    context:
      '使用網路訂票者，若須取消訂票，請依以下流程辦理：\n' +
      '訂購『團體優待票券/免費兌換券/愛心票/敬老票』者，請於該場次開演前20分鐘；個人專區「訂票記錄」中，直接取消訂票即可。\n' +
      '訂購『一般/銀行優惠』並於線上刷卡付費者，若尚未領取電影票；則請於該場次開演前 2小時進行線上退款申請；申請退款需支付線上退款手續費20元，原訂票記錄會變成「退款處理中」不能再進行取票，線上申請退款成功之後， 系統將會發 MAIL 通知\n' +
      '訂購『一般/銀行優惠』並於線上刷卡付費者，且已領取電影票；請於開演前 20 分鐘持未使用之電影票與原訂購之信用卡親至ATT影城辦理退換票，退換票恕不退還手續費。已取票或取餐者，無法進行線上退款，已取票或取餐者，無法進行線上退款。\n' +
      '電影票遺失恕不補發，亦無法辦理退換票。線上購票完成後退、換票，訂票手續費恕不退還，且不保證相同座位。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '買電影票為什麼沒有開立發票?',
    context:
      '依統一發票使用辦法第4條第27款規定，電影票本身已內含娛樂稅，故不會額外開立發票。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '『電影交換券』跟「預售票券」可以網路訂票嗎？',
    context:
      '『電影交換券』『預售票券』類，無法於網路訂票系統使用，請持票券至ATT影城現場劃位。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '如果遇到天災或特殊事故，該如何辦理電影票退票？',
    context:
      '因天災或特殊事故取消電影放映時，請於購票場次7日內憑『未使用之原票券』至購票櫃台辦理退換票。\n' +
      '◎影城未營業期間，網路訂票均不收取手續費。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
  {
    title: '團體優惠票券相關問題',
    context:
      '1、入場須知：將團體優惠票券交給窗口服務人員，兌換實體電影票入場。\n' +
      '2、團體優惠票券與一般票種、銀行優惠訂票，無法於同一筆交易完成，請分開訂購。\n' +
      '3、APP預訂團體優惠票僅收取訂票手續費，升級觀賞3D等…其他版本電影，另再需於窗口補       足差額即可使用。\n' +
      '4、過期團體優惠票券需支付每張票30元手續費即可使用，於影城窗口支付過期手續費。\n' +
      '5、團券須於場次開演前20分鐘至影城完成取票。否則將會取消該筆訂票，訂票手續費將不會退還。',
    type: COMMON_QUESTION_TYPE.ticketQuestion,
  },
];

function CommonQuestionsPage(props) {
  const [actions] = useOutlet('actions');
  const [activeQuestionType, setActiveQuestionType] = useState(
    COMMON_QUESTION_TYPE.userQuestion,
  );
  const [activeOpenQuestion, setActiveOpenQuestion] = useState(null);

  return (
    <Wrapper>
      <div className="view">
        <BlockSelector
          style={{width: '100%'}}
          active={activeQuestionType}
          setActive={setActiveQuestionType}
          options={configs}
        />
        {questions
          .filter((q) => q.type === activeQuestionType)
          .map((q, i) => {
            const isActive = q === activeOpenQuestion;
            return (
              <Card
                key={i}
                clickable
                onClick={() => {
                  setActiveOpenQuestion(q);
                }}
                style={{width: '100%', padding: 10, marginBottom: 10}}>
                {!isActive && <div style={{color: Color.black}}>{q.title}</div>}
                {isActive && (
                  <Fragment>
                    <div style={{color: Color.orange}}>{q.title}</div>
                    <div
                      style={{
                        marginTop: 8,
                        letterSpacing: 1,
                        fontSize: 12,
                        textAlign: 'left',
                        whiteSpace: 'pre-line',
                      }}>
                      {q.context}
                    </div>
                  </Fragment>
                )}
              </Card>
            );
          })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .view {
    flex: 1;
    ${FlexColumnStartStart};
  }
`;

export default CommonQuestionsPage;
